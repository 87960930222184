import { FormControlLabel, Radio } from '@material-ui/core';

import type { RadioProps, FormControlLabelProps } from '@material-ui/core';

interface OwnProps {
  value: string | boolean;
  castValue?: 'boolean';

  RadioProps?: Omit<RadioProps, 'color'>;
}

type Props = OwnProps & Omit<FormControlLabelProps, 'control' | 'value'>;

export function FormikRadioFieldOption({
  castValue,
  RadioProps,
  value,
  ...props
}: Props) {
  const ControlRadioProps = Object.assign(
    { color: 'primary' } as const,
    RadioProps
  );
  const stringValue = castValue === 'boolean' ? value.toString() : value;

  return (
    <FormControlLabel
      {...props}
      control={<Radio {...ControlRadioProps} />}
      label={props.label}
      value={stringValue}
    />
  );
}
