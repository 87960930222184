import { useNestedFormik } from '@src/components/form-sections/utils';
import {
  FormikDateField,
  FormikRadioField,
  FormikRadioFieldOption,
} from '@src/components/formik';
import { useEffect } from 'react';

export type IntentFormFieldsValues = {
  looking: boolean;
  startPref: string;
  earliestStartDateAt: Date | null;
};

export const IntentFormFields = () => {
  const { values, setFieldValue } = useNestedFormik<IntentFormFieldsValues>();

  useEffect(() => {
    if (!values.looking) {
      // this aims to hide the specific date field when the user is not looking
      setFieldValue('startPref', 'asap');
    }
  }, [values.looking, setFieldValue]);

  const lookingFieldName = 'looking';
  const startPrefFieldName = 'startPref';

  return (
    <div className="IntentFormFields">
      <div className="RadioField" key={`RadioField-${lookingFieldName}`}>
        <label className="FieldLabel" htmlFor={lookingFieldName}>
          Where are you in your job search?
        </label>

        <FormikRadioField
          fullWidth
          name={lookingFieldName}
          castValue="boolean"
          row
          RadioGroupProps={{ style: { gap: '1rem' } }}
        >
          <FormikRadioFieldOption
            key="option.looking-true"
            name={lookingFieldName}
            label="Looking for travel jobs"
            value="true"
            RadioProps={{ style: { padding: '0.3125rem' } }}
          />

          <FormikRadioFieldOption
            key="option.looking-false"
            name={lookingFieldName}
            label="Not actively looking"
            value="false"
            RadioProps={{ style: { padding: '0.3125rem' } }}
          />
        </FormikRadioField>
      </div>

      {values.looking && (
        <div className="RadioField" key={`RadioField-${startPrefFieldName}`}>
          <label className="FieldLabel" htmlFor={startPrefFieldName}>
            What is the earliest you could start?
          </label>
          <FormikRadioField
            fullWidth
            name={startPrefFieldName}
            row
            RadioGroupProps={{ style: { gap: '1rem' } }}
          >
            <FormikRadioFieldOption
              key="option.startPref-specific_date"
              name={startPrefFieldName}
              label="Specific Date"
              value="specific_date"
              RadioProps={{ style: { padding: '0.3125rem' } }}
            />
            <FormikRadioFieldOption
              key="option.startPref-asap"
              name={startPrefFieldName}
              label="I'm Flexible"
              value="asap"
              RadioProps={{ style: { padding: '0.3125rem' } }}
            />
          </FormikRadioField>
        </div>
      )}

      {values.startPref === 'specific_date' && (
        <FormikDateField
          fullWidth
          disablePast
          name="earliestStartDateAt"
          inputVariant="outlined"
        />
      )}

      <style jsx>{`
        .IntentFormFields {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        }

        .RadioField {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .FieldLabel {
          font-size: 1rem;
          font-weight: 600;
        }
      `}</style>
    </div>
  );
};
